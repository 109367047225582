import React from "react";
import { Row, Col } from "antd";

import "./MainBanner.scss";

export default function MainBanner() {
  return (
    <div className="main-banner">
      <div className="main-banner__dark" />
      <Row>
        <Col lg={4} />
        <Col lg={16}>
          <h2>
            Welcome to <br /> Ivan Llopis Personal Portal.
          </h2>
          <h3>
            I'm working happy to build a React Portal <br />
            Glad to meet you... see you soon
          </h3>
        </Col>
        <Col lg={4} />
      </Row>
    </div>
  );
}
